import { render, staticRenderFns } from "./PopupLayout.vue?vue&type=template&id=3d20e670&"
var script = {}
import style0 from "./PopupLayout.vue?vue&type=style&index=0&id=3d20e670&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\Bitnami\\jenkins-2.89.4-1\\apps\\jenkins\\jenkins_home\\jobs\\liveinfo3-front-user-dev\\workspace\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3d20e670')) {
      api.createRecord('3d20e670', component.options)
    } else {
      api.reload('3d20e670', component.options)
    }
    module.hot.accept("./PopupLayout.vue?vue&type=template&id=3d20e670&", function () {
      api.rerender('3d20e670', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/layouts/PopupLayout.vue"
export default component.exports